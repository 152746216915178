import { useLocation, Switch, Redirect } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import ProtectedRoutes from "../ProtectedRoutes";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "../ErrorBoundary";
import * as Sentry from "@sentry/react";
import React from "react";

import routes from "routes.js";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const { t } = useTranslation();
  const location = useLocation();
  const selectedLanguage = localStorage.getItem("i18nextLng");

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainContent && mainContent.current) mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <ProtectedRoutes
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
        // return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (props.location.pathname.indexOf(routes[i].path) !== -1) {
        return t(routes[i].name);
      }
    }
    return "Lottohoy";
  };

  return (
    <main>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          outterLink: `https://lottohoy.xyz/${selectedLanguage}`,
          imgSrc: require("../assets/img/simbalotto-logo.png"),
          imgAlt: "Lottohoy application logo",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <div
          className="container-fluid py-7 px-0"
          style={{ minHeight: "85vh" }}
        >
          <Sentry.ErrorBoundary fallback={ErrorBoundary} showDialog>
            <Switch>
              {getRoutes(routes)}
              {/* Redirect to dashboard if route not found */}
              <Redirect from="*" to="/dashboard" />
            </Switch>
          </Sentry.ErrorBoundary>
        </div>
        <AdminFooter />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
    </main>
  );
};

export default Admin;
